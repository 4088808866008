import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Avatar, Paper } from '@material-ui/core'
import { navigate } from 'gatsby'
import { useState } from '@hookstate/core'
import Grid from '@material-ui/core/Grid'
import { FollowModalState, StrategyState } from '../../state'
import { ROUTES } from '../../../../constant'
import CardChart from './CardChart'
import { useStyles } from './style'
import { useTranslation } from 'react-i18next'
import { LanguageState } from '../../../Layout/Header/state'
import { getLoginInfo } from '../../../../utils/localStorage'

export default function SingleStrategyCard({ record, subscribeList }) {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const unfollowOpenState = useState(FollowModalState.unfollowOpen)
  const publicFollowOpenState = useState(FollowModalState.publicFollowOpen)
  const modalStrategyIdState = useState(FollowModalState.strategyId)
  const strategyTimeState = useState(StrategyState.time)
  const languageState = useState(LanguageState.language)

  console.log(`=====record?.accumulated_profit==${record.id}====${record?.chartDataList}`)
  // console.log(`=====isObjEmpty(getObj(loginState))======${isObjEmpty(getObj(loginState))}`)

  return (
    <Paper className={classes.card} elevation={3}>
      <Grid container spacing={3}>
        <Grid item xs={3}>

          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Avatar className={classes.avatar} src={getLoginInfo()?.avatar} />
            </Grid>

            <Grid item xs={9}>
              <Typography className={classes.titleTypo} onClick={() => navigate(`${ROUTES.STRATEGY}/${record.id}`)}>
                {languageState.get() === 'en' ? record.name : record.titleVi != null ? record.titleVi : record.name}
              </Typography>
              <Typography className={classes.username} >{getLoginInfo()?.username}</Typography>
            </Grid>
          </Grid>

          <Typography  className={classes.description} >Equity Description</Typography>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.chartDiv}>
            <CardChart chartDataList={record.chartDataList} type={record.type} />
          </div>
        </Grid>
        <Grid item xs={2}>
          <Typography>Equity Name</Typography>
          <Typography>Equity Description</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>Equity Name</Typography>
          <Typography>Equity Description</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>Equity Name</Typography>
          <Typography>Equity Description</Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}
