import React, { useEffect } from 'react'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { useState } from '@hookstate/core'
import Typography from '@material-ui/core/Typography'
import { Paper, Toolbar } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { FollowModalState } from '../../state'
import { useStyles } from './style'
import { StaticImage } from 'gatsby-plugin-image'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { navigate } from 'gatsby'
import { ROUTES } from '../../../../constant'

export default function PublicFollowModalOutside() {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const openState = useState(FollowModalState.publicFollowOpen)
  const [expanded, setExpanded] = React.useState<string | false>(false)
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {
    if (!openState.get()) {
      setExpanded(false)
    }
  }, [openState.get()])

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openState.get()}
      onClose={() => {
        openState.set(false)
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openState.get()}>
        <Paper className={classes.paper}>
          <Toolbar variant="dense" className={classes.paperToolbar}>
            {t('How to Follow?')}
          </Toolbar>

          {/*<Typography className={classes.mainTypo}>It's seem like you're not logged in yet </Typography>*/}

          <div className={classes.divAccordion}>
            <Accordion className={classes.accordion} expanded={expanded === '1'} onChange={handleChange('1')}>
              <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography className={classes.heading}>{t('FOLLOW_STEP_1')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container justify="space-around">
                  <Paper elevation={7}>
                    <StaticImage width={200} src="../../../../assets/images/telegram/tlgProfile.jpg" alt="A dinosaur" />
                  </Paper>
                  <Paper elevation={7}>
                    <StaticImage width={200} src="../../../../assets/images/telegram/username.jpg" alt="A dinosaur" />
                  </Paper>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.accordion} expanded={expanded === '2'} onChange={handleChange('2')}>
              <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography className={classes.heading}>{t('FOLLOW_STEP_2')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container justify="space-around">
                  <Paper elevation={7}>
                    <StaticImage width={200} src="../../../../assets/images/telegram/search.jpg" alt="A dinosaur" />
                  </Paper>
                  <Paper elevation={7}>
                    <StaticImage width={200} src="../../../../assets/images/telegram/start.jpg" alt="A dinosaur" />
                  </Paper>
                  <Paper elevation={7}>
                    <StaticImage
                      width={200}
                      src="../../../../assets/images/telegram/chatWithBot.jpg"
                      alt="A dinosaur"
                    />
                  </Paper>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.accordion} expanded={expanded === '3'} onChange={handleChange('3')}>
              <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography className={classes.heading}>{t('FOLLOW_STEP_3')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container justify="space-around">
                  <Button size="small" variant={'contained'} className={classes.followButton} onClick={(e) => navigate(ROUTES.LOGIN)}>
                    Login
                  </Button>
                  <Button size="small" variant={'contained'} className={classes.followButton} onClick={(e) => navigate(ROUTES.REGISTER)}>
                    Register
                  </Button>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.accordion} expanded={expanded === '4'} onChange={handleChange('4')}>
              <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography className={classes.heading}>{t('FOLLOW_STEP_4')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container justify="space-around">
                  <Paper elevation={7}>
                    <StaticImage
                      width={200}
                      src="../../../../assets/images/telegram/profileScreen.png"
                      alt="A dinosaur"
                    />
                  </Paper>
                  <Paper elevation={7}>
                    <StaticImage
                      width={600}
                      src="../../../../assets/images/telegram/editTlgUsername.PNG"
                      alt="A dinosaur"
                    />
                  </Paper>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.accordion} expanded={expanded === '5'} onChange={handleChange('5')}>
              <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>{t('FOLLOW_STEP_5')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <Typography>{t('FOLLOW_STEP_5_1')}</Typography>
                  <Typography>{t('FOLLOW_STEP_5_2')}</Typography>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </Paper>
      </Fade>
    </Modal>
  )
}
