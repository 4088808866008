import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import CONF from '../../../../config'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      width: theme.spacing(120),
      // height: theme.spacing(80),
      backgroundColor: CONF.css.grey,
      // // border: '2px solid #000',
      // boxShadow: theme.shadows[5],
      // padding: theme.spacing(2, 4, 3),
      outline: 'none',
    },
    paperToolbar: {
      backgroundColor: CONF.css.green,
      color: CONF.css.white,
      width: '100%',
      // height: theme.spacing(1),
    },
    mainTypo: {
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    noButton: {
      marginRight: theme.spacing(10),
    },
    img: {
      width: theme.spacing(5),
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      // flexBasis: '33.33%',
      // flexShrink: 0,
    },
    followButton: {
      backgroundColor: CONF.css.orange,
      color: CONF.css.white,
      borderRadius: 20,
      width: 200,
      // margin: theme.spacing(2),
      '&:hover': {
        //you want this to be the same as the backgroundColor above
        backgroundColor: CONF.css.orange,
      },
      // marginBottom: theme.spacing(3),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    header: {
      // paddingTop: theme.spacing(3),
    },
    accordion: {
      // margin: theme.spacing(2),
    },
    accordionSummary: {
      // backgroundColor: CONF.css.grey
    },
    divAccordion: {
      margin: theme.spacing(2),
    },
  })
)
