import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useStyles } from './style'
import { CONST } from '../../../../../constant'
import { useTranslation } from 'react-i18next'

export default function CardChart({ chartDataList, type }) {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const options = {
    chart: {
      type: 'area',
      height: `78%`,
      zoomType: 'x',
    },
    rangeSelector: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      visible: false,
      type: 'datetime',
      labels: {
        // format: '{value:%Y-%m-%d}',
        format: '{value:%m/%Y}',
      },
      // startOnTick: true,
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        align: 'left',
        x: 0,
        y: -2,
      },
      gridLineWidth: 0.75,
    },
    legend: {
      // enabled: true,
      enabled: false,
    },
    tooltip: {
      useHTML: true,
      headerFormat: t('Date') + ': <b>{point.x:%Y/%m/%d}</b><br/>',
      // pointFormat: '{series.legendSymbol.element.outerHTML} {series.name}: {point.y:.2f} %<br/>',
      // shared: true,
      pointFormatter: function () {
        // @ts-ignore
        if (type === CONST.STRATEGY_TYPE.DERIVATIVES) {
          // @ts-ignore
          return this.series.name + ': <b>' + +this.y.toFixed(2) + ' p' + '</b><br/>'
        } else {
          // @ts-ignore
          return this.series.name + ': <b>' + +this.y.toFixed(2) + '%' + '</b><br/>'
        }
      },
    },
    plotOptions: {
      area: {
        lineColor: 'rgb(255,114,0)',
      },
      series: {
        turboThreshold: 3000 // Comment out this code to display error
      }
    },

    series: [
      {
        name: t('Profit'),
        data: chartDataList,
        marker: {
          enabled: false,
        },
        type: 'area',
        threshold: type === CONST.STRATEGY_TYPE.DERIVATIVES ? null : 100,
        color: 'rgb(255,242,232)',
        // color: 'rgb(255,114,0)',
        // fillColor: {
        //   linearGradient: {
        //     x1: 0,
        //     y1: 0,
        //     x2: 0,
        //     y2: 1,
        //   },
        //   stops: [
        //     // @ts-ignore
        //     [0, Highcharts.getOptions().colors[3]],
        //     // @ts-ignore
        //     [1, Highcharts.color(Highcharts.getOptions().colors[3]).setOpacity(0).get('rgba')],
        //   ],
        // },
      },
    ],
  }

  // return <HighchartsReact highcharts={Highcharts} options={options} constructorType={'stockChart'} />
  return <HighchartsReact highcharts={Highcharts} options={options} />
}
