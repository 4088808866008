import { createState } from '@hookstate/core'
import { IGalleryState, ISignalListState, IStrategyListState } from './interface'
import { deleteAxios, postAxios, putAxios } from '../../utils/parse'
import { API } from '../../constant'
import { setObj } from '../../utils/hookState'

export const StrategyState = {
  one: createState('{}'),
  list: createState<IStrategyListState>({ data: '[]', count: 0, subscribeList: [] }),
  listPageNo: createState(0),
  futuresList: createState<IStrategyListState>({ data: '[]', count: 0, subscribeList: [] }),
  futuresListPageNo: createState(0),
  chartPeriod: createState<string>('All'),
  time: createState<number>(+new Date()),
}

export const StrategyAction = {
  findAndCountEquity(filter = {}, getData = false, pageNo: number = 0, limit: number = 5) {
    return async () => {
      try {
        const params = { start: pageNo * limit, limit, filter, getData }
        const findResponse = await postAxios(API.STRATEGY.findAndCount, params)
        const countResponse = await postAxios(API.STRATEGY.count, params)

        StrategyState.list.set({
          data: JSON.stringify(findResponse.data),
          count: countResponse,
          subscribeList: findResponse.subscribeList,
        })
      } catch (err) {
        StrategyState.list.set({ data: '[]', count: 0, subscribeList: [] })
      }
    }
  },
  findAndCountFutures(filter = {}, getData = false, pageNo: number = 0, limit: number = 5) {
    return async () => {
      try {
        const params = { start: pageNo * limit, limit, filter, getData }
        const findResponse = await postAxios(API.STRATEGY.findAndCount, params)
        const countResponse = await postAxios(API.STRATEGY.count, params)

        StrategyState.futuresList.set({
          data: JSON.stringify(findResponse.data),
          count: countResponse,
          subscribeList: findResponse.subscribeList,
        })
      } catch (err) {
        StrategyState.futuresList.set({ data: '[]', count: 0, subscribeList: [] })
      }
    }
  },
  findOne(strategyId: number) {
    return async () => {
      try {
        const params = { strategyId }
        const findResponse = await postAxios(API.STRATEGY.findOne, params)

        setObj(StrategyState.one, findResponse)
      } catch (err) {
        setObj(StrategyState.one, {})
      }
    }
  },
  create(name: string, titleVi, description: string, descriptionVi, type, expectedProfit, expectedMaxDrawdown) {
    return async () => {
      const params = {
        name,
        titleVi,
        description,
        descriptionVi,
        type,
        expectedProfit,
        expectedMaxDrawdown,
      }
      await postAxios(API.STRATEGY.create, params)
    }
  },
  update(
    strategyId: number,
    name: string,
    titleVi,
    description: string,
    descriptionVi,
    type,
    expectedProfit,
    expectedMaxDrawdown
  ) {
    return async () => {
      try {
        const params = {
          strategyId,
          name,
          titleVi,
          description,
          descriptionVi,
          type,
          expectedProfit,
          expectedMaxDrawdown,
        }
        await postAxios(API.STRATEGY.update, params)
      } catch (err) {}
    }
  },
  active(strategyId: number) {
    return async () => {
      const params = { strategyId }
      await postAxios(API.STRATEGY.active, params)
    }
  },
  suspend(id: string) {
    return async () => {
      const params = { strategyId: id }
      await postAxios(API.STRATEGY.suspend, params)
    }
  },
  deleteZ(id: string) {
    return async () => {
      await deleteAxios(API.STRATEGY.deleteZ(id))
    }
  },
  follow(strategyId: string) {
    return async () => {
      try {
        const params = { strategyId }
        await postAxios(API.STRATEGY.follow, params)
      } catch (e) {
        return 'fail'
      }
    }
  },
  unfollow(strategyId: number) {
    return async () => {
      try {
        const params = { strategyId }
        await postAxios(API.STRATEGY.unfollow, params)
      } catch (e) {}
    }
  },
  addView(strategyId: string) {
    return async () => {
      try {
        const params = { strategyId }
        await postAxios(API.STRATEGY.addView, params)
      } catch (e) {}
    }
  },
}

export const SignalState = {
  list: createState<ISignalListState>({ data: '[]', count: 0 }),
  listPageNo: createState(0),
}

export const SignalAction = {
  findAndCount(strategyId: number, pageNo: number = 0, limit: number = 10) {
    return async () => {
      try {
        // SignalState.list.set({ data: '[]', count: 0 })

        const params = { strategyId, start: pageNo * limit, limit }
        const findResponse = await postAxios(API.SIGNAL.find, params)
        const countResponse = await postAxios(API.SIGNAL.count, params)

        SignalState.list.set({
          data: JSON.stringify(findResponse.data),
          count: countResponse,
        })
      } catch (err) {
        SignalState.list.set({ data: '[]', count: 0 })
      }
    }
  },
  create(strategyId: number, code: string, sellBuyType: string) {
    return async () => {
      const params = { strategyId, code, sellBuyType }
      await postAxios(API.SIGNAL.create, params)
    }
  },
  active(id: string) {
    return async () => {
      const params = { SIGNALId: id }
      await postAxios(API.SIGNAL.active, params)
    }
  },
  suspend(id: string) {
    return async () => {
      const params = { SIGNALId: id }
      await postAxios(API.SIGNAL.suspend, params)
    }
  },
  deleteZ(id: string) {
    return async () => {
      await deleteAxios(API.SIGNAL.deleteZ(id))
    }
  },
  update(id: string, name: string, description: string, type: string) {
    return async () => {
      const params = { name, description, type }
      await putAxios(API.SIGNAL.update(id), params)
    }
  },
}

export const GalleryState = {
  list: createState<string>('[]'),
}

export const GalleryAction = {
  find(strategyId: number) {
    return async () => {
      try {
        const params = { strategyId }
        const findResponse = await postAxios(API.GALLERY.find, params)
        setObj(GalleryState.list, findResponse.image_list)
      } catch (err) {
        setObj(GalleryState.list, [])
      }
    }
  },
  update(strategyId: number, imageList) {
    return async () => {
      try {
        const params = {
          strategyId,
          imageList,
        }
        await postAxios(API.GALLERY.update, params)
      } catch (err) {}
    }
  },
}

export const TradingHistoryState = {
  list: createState<ISignalListState>({ data: '[]', count: 0 }),
  listPageNo: createState(0),
}

export const TradingHistoryAction = {
  find(strategyId: number, pageNo: number = 0, limit: number = 10) {
    return async () => {
      try {
        const params = { strategyId, start: pageNo * limit, limit }
        const findResponse = await postAxios(API.SIGNAL.findTradingHistory, params)
        const countResponse = await postAxios(API.SIGNAL.countTradingHistory, params)

        TradingHistoryState.list.set({
          data: JSON.stringify(findResponse),
          count: countResponse,
        })
      } catch (err) {
        TradingHistoryState.list.set({ data: '[]', count: 0 })
      }
    }
  },
}

export const OpenPositionState = {
  list: createState<ISignalListState>({ data: '[]', count: 0 }),
  listPageNo: createState(0),
}

export const OpenPositionAction = {
  find(strategyId: number, pageNo: number = 0, limit: number = 10) {
    return async () => {
      try {
        const params = { strategyId, start: pageNo * limit, limit }
        const findResponse = await postAxios(API.SIGNAL.findOpenPosition, params)
        const countResponse = await postAxios(API.SIGNAL.countOpenPosition, params)

        OpenPositionState.list.set({
          data: JSON.stringify(findResponse),
          count: countResponse,
        })
      } catch (err) {
        OpenPositionState.list.set({ data: '[]', count: 0 })
      }
    }
  },
}

export const FollowModalState = {
  unfollowOpen: createState<boolean>(false),
  publicFollowOpen: createState<boolean>(false),
  strategyId: createState<number>(0),
}
