import React, { useEffect } from 'react'
import { useStyles } from './style'
import GridList from '@material-ui/core/GridList'
import Typography from '@material-ui/core/Typography'
import { useState } from '@hookstate/core'
import { FollowModalState, StrategyAction, StrategyState } from '../state'
import { getObj } from '../../../utils/hookState'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import UnfollowModalOutside from './UnfollowModalOutside'
import PublicFollowModalOutside from './PublicFollowModalOutside'
import SingleStrategyCard from './SingleCard'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { CONST } from '../../../constant'
import IconButton from '@material-ui/core/IconButton'
import CONF from '../../../config'
import { useTranslation } from 'react-i18next'
import { isScreenBiggerThan } from '../../../utils/parse'
import { useMobileStyles } from './styleMobile'

export default function VirtualTradingList() {
  const classes = useStyles()
  const classesMobile = useMobileStyles()
  const { t, i18n } = useTranslation()

  const listState = useState(StrategyState.list)
  const listPageNoState = useState(StrategyState.listPageNo)
  const futuresListState = useState(StrategyState.futuresList)
  const futuresListPageNoState = useState(StrategyState.futuresListPageNo)
  const strategyTimeState = useState(StrategyState.time)

  const [fetchCountState, setFetchCountState] = React.useState(CONF.cardFetchCount)
  const [categoryState, setCategoryState] = React.useState('')
  const [isViewMoreClicked, setIsViewMoreClicked] = React.useState(false)

  useEffect(() => {
    StrategyAction.findAndCountEquity({ type: CONST.STRATEGY_TYPE.EQUITY }, true, listPageNoState.get(), 2)()
  }, [listPageNoState.get(), strategyTimeState.get()])
  useEffect(() => {
    StrategyAction.findAndCountFutures(
      { type: CONST.STRATEGY_TYPE.DERIVATIVES },
      true,
      futuresListPageNoState.get(),
      1
    )()
  }, [futuresListPageNoState.get(), strategyTimeState.get()])

  function CategoryCardList({ categoryName, name, description, recordListState, pageNumberState }) {
    return ['', categoryName].includes(categoryState) ? (
      <>
        <Grid className={classes.titleGrid} container>
          <Grid container item xs={10} direction={'column'}>
            <Typography className={classes.header}>{name}</Typography>
            <Typography className={classes.subTitle}>{description}</Typography>
          </Grid>
          <Grid
            className={classes.viewMoreGrid}
            container
            item
            xs={2}
            justify={'center'}
            alignItems={'flex-end'}
            direction={'column'}
          >
            <div className={classes.arrowDiv}>
              <IconButton
                className={classes.rightArrow}
                onClick={() => {
                  pageNumberState.set(pageNumberState.get() - 1)
                }}
                disabled={pageNumberState.get() === 0}
                aria-label="previous page"
              >
                <ArrowLeftIcon fontSize={'large'} className={classes.leftArrow} />
              </IconButton>

              <IconButton
                className={classes.rightArrow}
                onClick={() => {
                  pageNumberState.set(pageNumberState.get() + 1)
                }}
                disabled={pageNumberState.get() >= Math.ceil(recordListState.count.get() / fetchCountState) - 1}
                aria-label="next page"
              >
                <ArrowRightIcon fontSize={'large'} className={classes.rightArrow} />
              </IconButton>
            </div>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        {getObj(recordListState.data).map((record) => (
          <div key={record.id}>
            <SingleStrategyCard record={record} subscribeList={recordListState.subscribeList.get()} />
          </div>
        ))}
      </>
    ) : (
      <></>
    )
  }

  function CategoryCardListMobile({ categoryName, name, description, recordListState, pageNumberState }) {
    return ['', categoryName].includes(categoryState) ? (
      <>
        <Grid className={classesMobile.titleGrid} container>
          <Grid container item xs={9} direction={'column'}>
            <Typography className={classesMobile.header}>{name}</Typography>
            <Typography className={classesMobile.subTitle}>{description}</Typography>
          </Grid>
          <Grid
            className={classesMobile.viewMoreGrid}
            container
            item
            xs={3}
            justify={'center'}
            alignItems={'flex-end'}
            direction={'column'}
          >
            {/*<ViewMoreButton category={categoryName} />*/}
            <div className={classesMobile.arrowDiv}>
              <IconButton
                className={classesMobile.rightArrow}
                onClick={() => {
                  pageNumberState.set(pageNumberState.get() - 1)
                }}
                disabled={pageNumberState.get() === 0}
                aria-label="previous page"
              >
                <ArrowLeftIcon fontSize={'large'} className={classesMobile.leftArrow} />
              </IconButton>

              <IconButton
                className={classesMobile.rightArrow}
                onClick={() => {
                  pageNumberState.set(pageNumberState.get() + 1)
                }}
                disabled={pageNumberState.get() >= Math.ceil(recordListState.count.get() / fetchCountState) - 1}
                aria-label="next page"
              >
                <ArrowRightIcon fontSize={'large'} className={classesMobile.rightArrow} />
              </IconButton>
            </div>
          </Grid>
        </Grid>
        <Divider className={classesMobile.divider} />
        <GridList cellHeight={380} className={classesMobile.gridList} cols={5}>
          {getObj(recordListState.data).map((record) => (
            <SingleStrategyCard key={record.id} record={record} subscribeList={recordListState.subscribeList.get()} />
          ))}
        </GridList>
      </>
    ) : (
      <></>
    )
  }

  return isScreenBiggerThan('md') ? (
    <div>
      <UnfollowModalOutside />
      <PublicFollowModalOutside />
      <Grid container>
        <Grid item md={10}>
          <Typography className={classes.disclaimer}> {t('STRATEGY_LIST_DISCLAIMER_1')}</Typography>
        </Grid>
        <Grid container item md={2} justify={'flex-end'}>
          <Typography className={classes.howToFollow} onClick={() => FollowModalState.publicFollowOpen.set(true)}>
            {t('How to Follow?')}
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />

      <CategoryCardList
        categoryName={CONST.STRATEGY_TYPE.EQUITY}
        name={t('EQUITY STRATEGIES')}
        description={t('EQUITY_SUBTITLE')}
        recordListState={listState}
        pageNumberState={listPageNoState}
      />

      <CategoryCardList
        categoryName={CONST.STRATEGY_TYPE.DERIVATIVES}
        name={t('FUTURES STRATEGIES')}
        description={t('FUTURES_SUBTITLE')}
        recordListState={futuresListState}
        pageNumberState={futuresListPageNoState}
      />

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography className={classes.bottomDisclaimer}>{t('STRATEGY_LIST_DISCLAIMER_2')}</Typography>
      </div>
    </div>
  ) : (
    <div>
      <UnfollowModalOutside />
      <PublicFollowModalOutside />
      <Typography className={classes.disclaimer}> {t('STRATEGY_LIST_DISCLAIMER_1')}</Typography>
      <Divider className={classes.divider} />

      <CategoryCardListMobile
        categoryName={CONST.STRATEGY_TYPE.EQUITY}
        name={t('EQUITY STRATEGIES')}
        description={t('EQUITY_SUBTITLE')}
        recordListState={listState}
        pageNumberState={listPageNoState}
      />

      <CategoryCardListMobile
        categoryName={CONST.STRATEGY_TYPE.DERIVATIVES}
        name={t('FUTURES STRATEGIES')}
        description={t('FUTURES_SUBTITLE')}
        recordListState={futuresListState}
        pageNumberState={futuresListPageNoState}
      />

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography className={classes.bottomDisclaimer}>{t('STRATEGY_LIST_DISCLAIMER_2')}</Typography>
      </div>
    </div>
  )
}
